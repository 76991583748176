// A lot of this taken from:
// https://github.com/awslabs/aws-mobile-appsync-sdk-js/blob/eae31121cd6add8b4b7ede3d1dd3d4f79e295f41/packages/aws-appsync/src/link/auth-link.ts

import * as Url from "url";
import { Signer } from "@aws-amplify/core";
import { print } from "graphql/language/printer";
import { ApolloLink, Observable } from "apollo-link";

const service = "appsync";

const formatAsRequest = ({ operationName, variables, query }, options) => {
  const body = {
    operationName,
    variables,
    query: print(query)
  };

  return {
    data: JSON.stringify(body),
    method: "POST",
    ...options,
    headers: {
      accept: "*/*",
      "content-type": "application/json; charset=UTF-8",
      ...options.headers
    }
  };
};

const iamBasedAuth = async (
  { credentials, region, url },
  operation,
  forward
) => {
  const { accessKeyId, secretAccessKey, sessionToken } = await credentials();
  const { host, path } = Url.parse(url);
  const formatted = {
    ...formatAsRequest(operation, {}),
    service,
    region,
    url,
    host,
    path
  };

  const { headers } = Signer.sign(formatted, {
    access_key: accessKeyId,
    secret_key: secretAccessKey,
    session_token: sessionToken
  });

  const origContext = operation.getContext();
  operation.setContext({
    ...origContext,
    headers: {
      ...origContext.headers,
      ...headers,
      "x-amz-user-agent": "elicient"
    }
  });

  return forward(operation);
};

const authLink = ({ url, region, auth: { credentials } }) =>
  new ApolloLink((operation, forward) => {
    let handle;
    return new Observable(observer => {
      iamBasedAuth({ credentials, region, url }, operation, forward).then(
        observable => {
          handle = observable.subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        }
      );

      return () => {
        if (handle) handle.unsubscribe();
      };
    });
  });

class AwsIamAuthLink extends ApolloLink {
  constructor(options) {
    super();
    this.link = authLink(options);
  }

  request(operation, forward) {
    return this.link.request(operation, forward);
  }
}

export default AwsIamAuthLink;
