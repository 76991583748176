import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

export default withStyles(theme => ({
  root: {
    color: "inherit",
    textDecoration: "none",
    fontWeight: 500,
    "&:hover": {
      textDecoration: "underline"
    }
  }
}))(
  React.memo(({ classes, children, ...rest }) => (
    <Link className={classes.root} {...rest}>
      {children}
    </Link>
  ))
);
