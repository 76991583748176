import React, { PureComponent } from "react";
import HtmlPage from "content/HtmlPage";
import AppShell from "./shell/AppShell";

class App extends PureComponent {
  render() {
    return (
      <HtmlPage documentTitle="Elicient - Discover the insights other ABS investors are missing">
        <AppShell />
      </HtmlPage>
    );
  }
}

export default App;
