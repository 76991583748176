import React, { PureComponent } from "react";
import DialogTitle from "@material-ui/core/DialogTitle/index";
import { withStyles } from "@material-ui/core/styles/index";
import classnames from "classnames";
import Grid from "@material-ui/core/Grid/index";
import IconButton from "@material-ui/core/IconButton/index";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography/index";

const DefaultIconButton = ({ hashless, goBack }) => (
  <IconButton
    color="inherit"
    component={hashless ? undefined : Link}
    to={hashless ? undefined : { hash: "#" }}
    onClick={hashless ? goBack : undefined}
  >
    <CloseIcon />
  </IconButton>
);

const styles = theme => ({
  dialogTitle: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    "& h2": { paddingLeft: theme.spacing(0.5) }
  },
  dialogTitleText: {
    "& h2": {
      color: theme.palette.common.white
    }
  }
});

class PrimaryDialogTitle extends PureComponent {
  render() {
    const {
      button,
      hashless,
      goBack,
      className,
      classes,
      children,
      ...rest
    } = this.props;
    return (
      <DialogTitle
        className={classnames(classes.dialogTitle, className)}
        classes={{ root: classes.dialogTitleText }}
        {...rest}
      >
        <Grid container alignItems="center">
          <Grid item xs={2} md={1}>
            {button || (
              <DefaultIconButton hashless={hashless} goBack={goBack} />
            )}
          </Grid>
          <Grid item xs={10} md={11}>
            <Typography color="inherit" variant="h6">
              {children}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
    );
  }
}

export default withStyles(styles)(PrimaryDialogTitle);
