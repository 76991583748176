import React, { Suspense } from "react";
import { withRouter } from "react-router";
import Dialog from "@material-ui/core/Dialog";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Backdrop from "@material-ui/core/Backdrop";
import ThirdPartyAccessDialog from "content/auth/ExternalAccessDialog";

const SignUp = React.lazy(() => import("content/auth/flow/SignUp"));
const Login = React.lazy(() => import("content/auth/flow/Login"));
const ContactUs = React.lazy(() => import("content/contact-us/ContactUs"));

export default withRouter(
  withWidth()(
    React.memo(({ location, width }) => {
      return (
        <Dialog
          open={!!location.hash && location.hash.startsWith("#!")}
          fullScreen={!isWidthUp("md", width)}
        >
          {location.hash.startsWith("#!/signup") && (
            <Suspense fallback={<Backdrop open />}>
              <SignUp />
            </Suspense>
          )}
          {location.hash.startsWith("#!/login") && (
            <Suspense fallback={<Backdrop open />}>
              <Login />
            </Suspense>
          )}
          {location.hash.startsWith("#!/contact-us") && (
            <Suspense fallback={<Backdrop open />}>
              <ContactUs />
            </Suspense>
          )}
          {location.hash.startsWith("#!/external-app-access") && (
            <Suspense fallback={<Backdrop open/>}>
              <ThirdPartyAccessDialog />
            </Suspense>
          )}
        </Dialog>
      );
    })
  )
);
