import React, { useState } from "react";
import DialogTitle from "material-ui/PrimaryDialogTitle";
import {
  DialogContent,
  Typography,
  makeStyles,
  Table,
  TableBody,
  TableRow,
  TableCell,
  DialogActions,
  IconButton,
  Button,
  CircularProgress,
  Grid
} from "@material-ui/core";
import Link from "material-ui/StyledLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-duotone-svg-icons";
import { faEyeSlash } from "@fortawesome/pro-duotone-svg-icons";
import useGetExternalAccessCredentialsQuery from "./useGetExternalAccessCredentialsQuery";

const useStyles = makeStyles(theme => ({
  emphasis: {
    fontWeight: "bold"
  },
  overview: {
    fontSize: "0.83rem",
    marginBottom: theme.spacing(1)
  },
  disabled: {
    fontSize: "0.83rem",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: theme.palette.error.main
  }
}));

export default () => {
  const classes = useStyles();
  const { data, loading } = useGetExternalAccessCredentialsQuery();
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <DialogTitle>External Application Access</DialogTitle>
      <DialogContent>
        <Typography className={classes.overview}>
          Use the credentials below to connect directly to our database from R,
          Python, Tableau, or virtually any modern language, data analytics
          tool, or framework.
        </Typography>
        <Typography className={classes.overview}>
          <span className={classes.emphasis}>
            Treat these credentials like you would your own password.
          </span>{" "}
          Don't share them with others! They allow access to the dataset on your
          behalf. If you believe your credentials have been compromised, please{" "}
          <Link to="#!/contact-us">contact us</Link> immediately.
        </Typography>
        {(loading || !data || !data.externalAccessCredentials) && (
          <Grid container justify="center" align="center">
            <Grid item>
              {loading ? (
                <CircularProgress size={60} color="secondary" />
              ) : (
                <Typography
                  style={{ textAlign: "left" }}
                  className={classes.disabled}
                >
                  External access is currently not enabled for this account.
                  Please{" "}
                  <Link
                    to={{ hash: "#!/contact-us" }}
                    style={{ fontWeight: "bold" }}
                  >
                    Contact us
                  </Link>{" "}
                  to enable it.
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
        {data && data.externalAccessCredentials && !loading && (
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.emphasis}>Endpoint</TableCell>
                <TableCell colSpan={2}>
                  {data.externalAccessCredentials.endpoint}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.emphasis}>Port number</TableCell>
                <TableCell colSpan={2}>
                  {data.externalAccessCredentials.portNumber}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.emphasis}>Database</TableCell>
                <TableCell colSpan={2}>
                  {data.externalAccessCredentials.database}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.emphasis}>Username</TableCell>
                <TableCell colSpan={2}>
                  {data.externalAccessCredentials.username}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.emphasis}>Password</TableCell>
                <TableCell>
                  {showPassword
                    ? data.externalAccessCredentials.password
                    : "********"}
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    size="small"
                    onClick={() => setShowPassword(old => !old)}
                    title={showPassword ? "Hide password" : "Show password"}
                  >
                    <FontAwesomeIcon
                      icon={showPassword ? faEyeSlash : faEye}
                      size="sm"
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions style={{ textAlign: "right" }}>
        <Button
          component={Link}
          to={{ hash: "#" }}
          color="primary"
          variant="contained"
        >
          Close
        </Button>
      </DialogActions>
    </>
  );
};
