import React, { useState, useEffect, useContext } from "react";
import AuthContext from "auth/AuthContext";
import useLoggedInState from "auth/useLoggedInState";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink, concat } from "apollo-link";
import AwsIamAuthLink from "./AwsIamAuthLink";

const AppSyncApolloClientContext = React.createContext({});

export const AppSyncApolloClientProvider = ({ children }) => {
  const { auth } = useContext(AuthContext);

  const [previousLoggedInState, setPreviousLoggedInState] = useState();
  const { isPending: isLoggedInStatePending, isLoggedIn } = useLoggedInState();

  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        }
        if (networkError) {
          console.log(`[Network error]: ${networkError}`);
        }
      }),
      concat(
        new AwsIamAuthLink({
          url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
          region: "us-east-1",
          auth: { credentials: auth.currentCredentials }
        }),
        new HttpLink({
          uri: process.env.REACT_APP_GRAPHQL_ENDPOINT
        })
      )
    ]),
    cache: new InMemoryCache({
      cacheRedirects: {
        Query: {
          SqlQuery: (_, args, { getCacheKey }) =>
            getCacheKey({ __typename: "SqlQuery", id: args.id })
        }
      }
    })
  });

  useEffect(() => {
    if (!isLoggedInStatePending && isLoggedIn !== previousLoggedInState) {
      if (typeof previousLoggedInState !== "undefined") {
        client.resetStore();
      }
      setPreviousLoggedInState(isLoggedIn);
    }
  }, [previousLoggedInState, isLoggedIn, isLoggedInStatePending, client]);

  return (
    <AppSyncApolloClientContext.Provider value={{ client }}>
      {children}
    </AppSyncApolloClientContext.Provider>
  );
};

export const AppSyncApolloClientConsumer = AppSyncApolloClientContext.Consumer;
export default AppSyncApolloClientContext;
