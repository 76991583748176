import React, { useMemo } from "react";

const AceEditorSessionContext = React.createContext();

export const AceEditorSessionProvider = React.memo(({ children }) => {
  const sessions = useMemo(() => new Map(), []);

  const cacheSession = (queryId, session) => sessions.set(queryId, session);
  const getSession = queryId => sessions.get(queryId);

  return (
    <AceEditorSessionContext.Provider value={{ cacheSession, getSession }}>
      {children}
    </AceEditorSessionContext.Provider>
  );
});

export const AceEditorSessionConsumer = AceEditorSessionContext.Consumer;

export default AceEditorSessionContext;
