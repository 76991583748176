import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import { AppSyncApolloClientProvider } from "apollo/AppSyncApolloClientContext";
import { AuthProvider } from "auth/AuthContext";
import React, { Suspense, useState } from "react";
import AppRouter from "./AppRouter";
import AppUpdateSnackbar from "./AppUpdateSnackbar";
import ElicientAppBar from "./ElicientAppBar";
import theme from "./Theme";
import FullWidthLinearProgress from "material-ui/FullWidthLinearProgress";
import { AceEditorSessionProvider } from "content/query-editor/text-editor/ace-editor/AceEditorSessionContext";
import { PendingQueryChangesStatusProvider } from "content/query-editor//text-editor/PendingQueryChangesStatusContext";

const AppNavigationDrawer = React.lazy(() => import("./AppNavigationDrawer"));

const useStyles = makeStyles(() => ({
  "@global": {
    body: {
      ...theme.typography.body1
    },
    ".__resizable_base__": {
      // without this, ie11/edge overflows 100vh pages.
      // this is probably breaking something I don't know about yet
      height: "0 !important"
    }
  },
  root: {
    zIndex: 1,
    position: "relative",
    width: "100%",
    height: "100%"
  }
}));

/**
 * The only direct descendent of the App component, containing the main site
 * navigation drawer, toolbar, and content area.
 */
export default React.memo(() => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <React.Fragment>
        <AppUpdateSnackbar />
        <AuthProvider>
          <AppSyncApolloClientProvider>
            <AceEditorSessionProvider>
              <PendingQueryChangesStatusProvider>
                <AppRouter>
                  {content => (
                    <div className={classes.root}>
                      <ElicientAppBar toggleDrawer={toggleDrawer} />
                      <Suspense fallback={<FullWidthLinearProgress />}>
                        <AppNavigationDrawer isOpen={isDrawerOpen}>
                          {content()}
                        </AppNavigationDrawer>
                      </Suspense>
                    </div>
                  )}
                </AppRouter>
              </PendingQueryChangesStatusProvider>
            </AceEditorSessionProvider>
          </AppSyncApolloClientProvider>
        </AuthProvider>
      </React.Fragment>
    </MuiThemeProvider>
  );
});
