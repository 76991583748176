import React, { useState } from "react";

const PendingQueryChangesStatusContext = React.createContext();

export const PendingQueryChangesStatusProvider = React.memo(({ children }) => {
  const [areChangesPending, setChangesPending] = useState(false);
  return (
    <PendingQueryChangesStatusContext.Provider
      value={{ areChangesPending, setChangesPending }}
    >
      {children}
    </PendingQueryChangesStatusContext.Provider>
  );
});

export const PendingQueryChangesStatusConsumer =
  PendingQueryChangesStatusContext.Consumer;

export default PendingQueryChangesStatusContext;
