import { useContext, useEffect, useState } from "react";
import gql from "graphql-tag";
import AuthContext from "auth/AuthContext";
import useAppSyncQuery from "apollo/useAppSyncQuery";
import useLoggedInState from "auth/useLoggedInState";

const GET_CREDS = gql`
  query ExternalAccessCredentials($token: ID!) {
    externalAccessCredentials(token: $token) {
      endpoint
      portNumber
      database
      username
      password
    }
  }
`;

export default () => {
  const { auth } = useContext(AuthContext);
  const [token, setToken] = useState();

  const { isLoggedIn } = useLoggedInState();

  const query = useAppSyncQuery(GET_CREDS, {
    variables: {
      token
    },
    skip: !isLoggedIn || !token
  });

  useEffect(() => {
    if (isLoggedIn) {
      auth.currentSession().then(u => {
        setToken(u.idToken.jwtToken);
      });
    }
  }, [auth, isLoggedIn]);

  return { ...query, loading: query.loading || !token };
};
