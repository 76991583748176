import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    primary: { main: "#b014f7", darker: "#44284f", darkest: "#1e1123" },
    secondary: { main: "#f85f13" },
    success: { main: "#0dceb7" },
    accent: { main: "#0eb5f2" }
  },
  shell: {
    appBar: {
      height: 64
    }
  },
  typography: {
    fontFamily: [
      "Whitney SSm A",
      "Whitney SSm B",
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif"
    ]
  },
  h1: { fontSize: "1.75rem" }
});
