import React, { useState, useEffect } from "react";
import {
  Button,
  Snackbar,
  SnackbarContent,
  IconButton
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
  snackbar: {
    backgroundColor: theme.palette.secondary.main,
    opacity: 0.9
  }
}));

export default React.memo(() => {
  const classes = useStyles();

  const [hub, setHub] = useState();
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [isNotificationSuppressed, setIsNotificationSuppressed] = useState(
    false
  );

  const onUpdateAvailable = capsule => {
    switch (capsule.payload.event) {
      case "available":
        setIsUpdateAvailable(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    import("@aws-amplify/core").then(Amplify => {
      const Hub = Amplify.Hub;
      Hub.listen("appUpdate", onUpdateAvailable);
      setHub(Hub);
    });
    return () => {
      if (hub) {
        hub.remove("appUpdate", onUpdateAvailable);
      }
    };
  }, [hub]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open={isUpdateAvailable && !isNotificationSuppressed}
    >
      <SnackbarContent
        className={classes.snackbar}
        message="A new version is available! Close and re-open this browser tab to get it."
        action={[
          <IconButton key={1} onClick={() => setIsNotificationSuppressed(true)}>
            <CloseIcon />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
});
