import { AppBar, IconButton, Toolbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/styles";
import logo from "assets/images/logos/elicient-logo-color-solid-text.svg";
import React from "react";
import { Link } from "react-router-dom";

const TryNowButton = React.lazy(() =>
  import("content/auth/TryFreeButtonOrUserSnippet")
);

/**
 * Defines the css styles used by the component as function of the material-ui
 * theme defined for the app, to be applied as modularized classes by the
 * material-ui withStyles HOC.
 */
const useStyles = makeStyles(theme => ({
  appBar: {
    height: theme.shell.appBar.height,
    zIndex: theme.zIndex.drawer + 1
  },
  toolbar: {
    display: "flex"
  },
  rightContent: {
    marginLeft: "auto"
  }
}));

/**
 * The static toolbar displayed at the top of all pages, containing a drawer
 * toggle button and the Elicient logo.
 */
export default React.memo(({ toggleDrawer }) => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" color="default" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          data-testid="menu-button"
        >
          <MenuIcon />
        </IconButton>
        <Link to="/">
          <img
            src={logo}
            className={classes.logo}
            alt="Elicient logo"
            width="187.5"
            height="65"
          />
        </Link>
        <div className={classes.rightContent}>
          <React.Suspense fallback={null}>
            <TryNowButton />
          </React.Suspense>
        </div>
      </Toolbar>
    </AppBar>
  );
});
