import React from "react";
import { Helmet } from "react-helmet";

export default React.memo(({ documentTitle, metaDescription, children }) => (
  <React.Fragment>
    <Helmet>
      {documentTitle && <title>{documentTitle}</title>}
      {metaDescription && <meta name="description" content={metaDescription} />}
    </Helmet>
    {children}
  </React.Fragment>
));
