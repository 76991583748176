import { useState, useEffect, useContext } from "react";
import AuthContext from "auth/AuthContext";

export default () => {
  const [isPending, setisPending] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState();
  const { isPending: isAuthPending, auth } = useContext(AuthContext);

  useEffect(() => {
    let listener, Hub;
    (async () => {
      Hub = (await import("@aws-amplify/core")).Hub;
      if (!isAuthPending && auth) {
        setIsLoggedIn((await auth.currentUserCredentials()).authenticated);
        if (!listener) {
          listener = Hub.listen("auth", capsule => {
            switch (capsule.payload.event) {
              case "signIn":
                setIsLoggedIn(true);
                break;
              case "signOut":
                setIsLoggedIn(false);
                break;
              default:
                break;
            }
          });
        }
        setisPending(false);
      }
    })();

    return () => {
      if (Hub && listener) {
        Hub.remove("auth", listener);
      }
    };
  }, [auth, isAuthPending]);
  return { isPending, isLoggedIn };
};
