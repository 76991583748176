import React, { Children, Suspense } from "react";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import GlobalDialogs from "./GlobalDialogs";
import FullWidthLinearProgress from "material-ui/FullWidthLinearProgress";

const Homepage = React.lazy(() => import("content/homepage/HomePage"));

const QueryEditorPage = React.lazy(() =>
  import("content/query-editor/QueryEditorPage")
);

const RollRatesPage = React.lazy(() =>
  import("content/roll-rates/RollRatesPage")
);

const BlogPage = React.lazy(() => import("content/blog/BlogPage"));

export default React.memo(({ children }) => {
  return (
    <BrowserRouter>
      <React.Fragment>
        {Children.only(
          children(() => {
            return (
              <React.Fragment>
                <Route
                  /* Force a trailing slash
                   * https://github.com/ReactTraining/react-router/issues/820#issuecomment-313794272
                   */
                  path="/:url*"
                  exact
                  strict
                  render={props => (
                    <Redirect to={`${props.location.pathname}/`} />
                  )}
                />
                <Route
                  exact
                  path="/"
                  render={() => (
                    <Suspense fallback={<FullWidthLinearProgress />}>
                      <Homepage />
                    </Suspense>
                  )}
                />
                <Route
                  strict
                  path="/query/"
                  render={() => (
                    <Suspense fallback={<FullWidthLinearProgress />}>
                      <QueryEditorPage />
                    </Suspense>
                  )}
                />
                <Route
                  strict
                  path="/roll-rates/"
                  render={() => (
                    <Suspense fallback={<FullWidthLinearProgress />}>
                      <RollRatesPage />
                    </Suspense>
                  )}
                />
                <Route
                  strict
                  path="/blog/"
                  render={() => (
                    <Suspense fallback={<FullWidthLinearProgress />}>
                      <BlogPage />
                    </Suspense>
                  )}
                />
              </React.Fragment>
            );
          })
        )}
        <GlobalDialogs />
      </React.Fragment>
    </BrowserRouter>
  );
});
