import gql from "graphql-tag";
import uuid from "uuid/v4";

// https://stackoverflow.com/a/38340730/253693
const removeEmpty = obj => {
  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === "object") removeEmpty(val);
    else if (val == null || val === "") delete obj[key];
  });
  return obj;
};

const clientId = () => {
  const keyName = "elicientClientId";
  if (!localStorage.getItem(keyName)) {
    localStorage.setItem(keyName, uuid());
  }
  return localStorage.getItem(keyName);
};

const baseInteraction = () => ({
  clientId: clientId(),
  userSession: {
    userAgent: navigator.userAgent
  },
  trafficSource: {
    documentReferrer: document.referrer
  },
  systemInfo: {
    screenResolution: `${global.screen.width}x${global.screen.height}`,
    viewportSize: `${Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    )}x${Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    )}`,
    screenColors: global.screen.colorDepth,
    userLanguage: navigator.language
  },
  contentInfo: {
    documentLocation: document.location.href,
    documentTitle: document.title
  }
});

const pageView = () => ({ ...baseInteraction(), hitType: "PAGEVIEW" });
const eventMutation = gql`
  mutation trackInteraction($interaction: TrackedInteraction) {
    trackInteraction(interaction: $interaction)
  }
`;

export const trackPageView = client =>
  client.mutate({
    mutation: eventMutation,
    variables: { interaction: removeEmpty(pageView()) }
  });

const event = (action, category, label, value, isInteractive = true) => ({
  ...baseInteraction(),
  hitType: "EVENT",
  isInteractive,
  eventInfo: {
    action,
    category,
    label,
    value: value ? Math.round(value) : value
  }
});

export const trackEvent = (
  client,
  eventAction,
  eventCategory,
  eventLabel,
  eventValue,
  isInteractive
) =>
  client.mutate({
    mutation: eventMutation,
    variables: {
      interaction: removeEmpty(
        event(eventAction, eventCategory, eventLabel, eventValue, isInteractive)
      )
    }
  });
