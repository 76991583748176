import React, { useEffect } from "react";
import Amplify from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  useEffect(() => {
    Amplify.configure({
      Auth: {
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID
      }
    });
  }, []);

  return (
    <AuthContext.Provider value={{ auth: Auth }}>
      {children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;

export default AuthContext;
